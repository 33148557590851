@import '../../../styles/_globalVariables.scss';

.mb50 {
  margin-bottom: 40px;
  @include for-size(tablet-up) {
    margin-bottom: 50px;
  }
}

.wrapper {
  margin: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 319px;
  text-align: center;
  line-height: 0px;
}

.image {
  width: 160px;
  height: 97.074px;
}

.heading {
  color: $lightBlack;
  margin-top: 24px;
  font-size: 20px;
  font-style: normal;
  font-weight: $font-semi-bolder;
  line-height: 28px;
}

.btn {
  margin: 24px auto 0 auto;
  width: 232px;
}
